/* eslint-disable */
import {ColumnConfig, IColumnConfig} from './columnConfig';
import { ColumnTemplateType } from './enums';

export class TextBooleanDateTimeColumnConfig extends ColumnConfig {

  dataFieldBoolean: string;
  dataFieldDateTime: string;

  constructor(config: ITextBooleanDateTimeColumnConfig) {
    if (!config.editTemplateName) config.editTemplateName = ColumnTemplateType.TextBooleanDateTime;
    if (!config.defaultValue) config.defaultValue = false;
    super(config);
    this.dataFieldBoolean = config.dataFieldBoolean;
    this.displayField = this.dataField;
  }
}

export interface ITextBooleanDateTimeColumnConfig extends IColumnConfig {
  dataFieldBoolean: string;
  dataFieldDateTime: string;
}
